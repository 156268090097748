import React from 'react';
import { Link } from 'gatsby';

import styles from './css/index.module.sass';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Photos from '../components/Photos';

const PhotosPage = () => (
  <Layout>
    <SEO title="Photos" />
    <div className={styles.paragraphs}>
      <Photos />
    </div>
  </Layout>
);

export default PhotosPage;
