import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Gallery } from 'gatsby-theme-gallery';
import styles from './index.module.sass';
import { Col, Row } from 'react-bootstrap';
import { FaFacebookSquare, FaInstagram, FaArrowLeft } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';
import BackArrowTitle from '../Common/BackArrowTitle';

import Copyright from './copyright';

const Photos = () => {
  return (
    <div className={styles.container}>
      <BackArrowTitle title="Photographies" />
      <Gallery />
      <Copyright />
    </div>
  );
};

export default Photos;
