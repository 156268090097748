import React from 'react';
import styles from './index.module.sass';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';


const Copyright = () => {

    return (
        <Row className={styles.copyright}>
            <Col xs={12} sm={12} md={6} lg={6}>
                <span>John Photography</span>
                <a href="https://www.facebook.com/johnaphotographie/" rel="noopener noreferrer" target="_blank">
                    <FaFacebookSquare className={styles.icon} />
                </a>
                <a href="https://www.instagram.com/john_photography_france/?hl=fr" rel="noopener noreferrer" target="_blank">
                    <FaInstagram className={styles.icon} />
                </a>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <span className={styles.computer}>VB Photographer</span>
                <a href="https://www.facebook.com/VBPHOTOWW2/" rel="noopener noreferrer" target="_blank">
                    <FaFacebookSquare className={styles.icon} />
                </a>
                <a href="https://www.instagram.com/vb_us_army_photographer_/?hl=fr" rel="noopener noreferrer" target="_blank">
                    <FaInstagram className={styles.icon} />
                </a>
                <span className={styles.mobile}>VB Photographer</span>
            </Col>
        </Row>
    );
};

export default Copyright;
